/* 記事詳細 */
.post-detail {
  width: 80%;
  margin-bottom: 40px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.post-detail:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-title {
  font-size: 2rem;
  color: #0070f3;
  margin-bottom: 10px;
}

.post-date {
  font-size: 0.875rem;
  color: #999999;
  margin-bottom: 20px;
}

.post-content h2 {
  font-size: 1.5rem;
  color: #333333;
  margin-top: 20px;
  margin-bottom: 10px;
  border-left: 4px solid #0070f3;
  padding-left: 10px;
}

.post-content p {
  font-size: 1rem;
  margin-bottom: 15px;
}

.post-content pre {
  background-color: #f9f9f9;
  padding: 15px;
  border-radius: 5px;
  font-size: 0.9rem;
  overflow-x: auto;
}
