.App-header {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  padding: 20px 0;
  border-bottom: 1px solid #eaeaea;
}

.App-link {
  color: #61dafb;
}

.container {
  width: 90%;
  max-width: 800px;
  margin: 10px auto 0;
  display: flex;
  justify-content: space-between;
  align-items: start;
  width: 90%;
  max-width: 1200px;
}

/* メインセクション */
.main {
  padding: 20px 0;
}

.posts {
  width: 80%;
  display: block;
}

/* 記事 */
.post {
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
  margin-bottom: 15px;
}

.post:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.post-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.post-title a {
  text-decoration: none;
  color: #0070f3;
}

.post-title a:hover {
  text-decoration: underline;
}

.post-date {
  font-size: 0.875rem;
  color: #999999;
}

.post-excerpt {
  font-size: 1rem;
  color: #555555;
  margin-top: 10px;
}

/* フッター */
.footer {
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #eaeaea;
  margin-top: 20px;
}

.footer p {
  font-size: 0.875rem;
  color: #999999;
}

/* サイドバー */
.sidebar {
  margin-left: 20px;
  width: 20%;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  padding: 15px;
  transition: box-shadow 0.3s ease;
}

.sidebar:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.widget {
  margin-bottom: 20px;
}

.widget-title {
  font-size: 1.4rem;
  color: #0070f3;
  margin-bottom: 15px;
  padding-bottom: 5px;
  border-bottom: 2px solid #eaeaea; /* アンダーラインでアクセント */
}

.widget-list {
  list-style: none;
  padding: 0;
}

.widget-list a {
  display: flex; /* アイコンを並べるためにフレックス */
  align-items: center;
  text-decoration: none;
  font-size: 1rem;
}

.search-box {
  width: 95%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
}
.search-box input[type='text'] {
  padding: 8px;
  border: none;
  outline: none;
}
.search-box button {
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
}
.search-box button:hover {
  background-color: #0056b3;
}

.tag-list {
  list-style-type: none;
  padding: 0;
}

.tag-list li {
  padding: 0 5px;
  list-style-type: none;
}

.tag-list li a {
  text-decoration: none;
}

.effect a {
  list-style-type: none;
  color: #333333;
  border-radius: 5px;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
}

.effect a:hover {
  background-color: #f0f4ff; /* ホバー時に背景色を変更 */
  color: #0070f3;
}

.with-dot a::before {
  content: '•'; /* アイコン代わりの丸 */
  font-size: 1.5rem;
  color: #0070f3;
  margin-right: 10px;
}

.tag-list {
  list-style-type: none;
}

.tag-list li {
  display: inline-block;
  margin: 5px 0;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}
